import React, { useEffect, useState } from 'react';
import './Auth.css';
import { login,getUser } from '../../util/AuthApiService'
import { useNavigate } from 'react-router-dom';

const Auth = () => {
  const navigate = useNavigate();
  const [signup, setSignup] = useState(false)
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('users')));
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    referral: ''
  });
  useEffect(() => {
    if(user){
      navigate('/')
    }
  })
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleLogin = () => {
    setSignup(false);
  }
  const handleSignup = () => {
    setSignup(true);
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    console.log('Form submitted:', formData);
  };
  const handleLoginSubmit = () => {
    login(formData).then((response) => {
     if(response.data="Login Successful"){
      getUser(formData.email).then((response)=>{
        localStorage.setItem("users",JSON.stringify(response.data))
      })
      navigate('/')
     }
    })
      .catch((err) => {
        console.error("Failed to Login", err);
      });
  }
  return (
    <div className="auth-container">
      <div className="auth-content">
        {/* Left side - Image */}
        <div className="auth-image">
          <img
            src="loginscreen.png"
            alt="Shopping cart with mobile phone"
            className="signup-image"
          />
        </div>
        {signup ? (
          <div className="auth-form-container">
            <div className="auth-form-content">
              <h1 className="auth-title">Create an account</h1>
              <p className="auth-subtitle">Enter your details below</p>

              <form onSubmit={handleSubmit} className="auth-form">
                <div className="form-field">
                  <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-field">
                  <input
                    type="text"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-field">
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-field">
                  <input
                    type="text"
                    name="referral"
                    placeholder="Referral (optional)"
                    value={formData.referral}
                    onChange={handleChange}
                  />
                </div>

                <button type="submit" className="submit-button">
                  Create Account
                </button>
              </form>

              <div className="auth-footer">
                <span>Already have account? </span>
                <a onClick={() => handleLogin()} className="login-link">Log in</a>
              </div>
            </div>
          </div>
        ) : (
          <div className="auth-form-container">
            <div className="auth-form-content">
              <h1 className="auth-title">Welcome Back !</h1>
              <p className="auth-subtitle">Enter your details below</p>

              <form onSubmit={handleSubmit} className="auth-form">
                <div className="form-field">
                  <input
                    type="text"
                    name="email"
                    placeholder="Email "
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-field">
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                </div>

                <button type="submit" className="submit-button" onClick={() => handleLoginSubmit()}>
                  Login
                </button>
              </form>

              <div className="auth-footer">
                <span>Don't you have account? </span>
                <a onClick={() => handleSignup()} className="login-link">Sign up</a>
              </div>
            </div>
          </div>
        )};
      </div>
    </div>
  );
};

export default Auth;