
export const tw = {
    deals: {
        seasonLabel: "本季",
        title: "特價優惠",
        viewDeals: "查看優惠"
    },
    labels: {
        today: "今日",
        flashSales: "限時特賣",
        thisSeason: "本季",
        shopCollections: "按系列購物",
        thisMonth: "本月",
        bestSelling: "暢銷產品",
        ourProducts: "我們的產品",
        exploreProducts: "探索我們的產品",
        featured: "精選",
        newArrivals: "新品上市",
        viewAll: "查看所有產品",
        viewCollection: "查看系列",
        addToCart: "加入購物車",
        separator: "|",
        profile: "我的個人資料",
        address: "地址簿",
        payment: "我的付款選項",
        orders: "我的訂單",
        returns: "我的退貨",
        cancellations: "我的取消",
        wishlist: "我的願望清單",
        referral: "推薦",
        subscription: "訂閱",
        manageAccount: "管理我的帳戶",
    myOrders: "我的訂單",
    others: "其他",
    editProfile: "編輯您的個人資料",
    email: "電子郵件",
    subscriptionType: "訂閱類型",
    referralCode: "推薦碼",
    referredBy: "被推薦人",
    username: "用戶名",
    updatePassword: "更新密碼",
    enterOtp: "輸入 OTP",
    resendOtpIn: "在...內重新發送 OTP",
    resendOtp: "重新發送 OTP",
    setNewPassword: "設置新密碼",
    savePassword: "保存密碼",
    cancel: "取消",
    saveChanges: "保存更改"
    },
    header: {
        announcement: "所有泳裝夏季促銷，免費特快送貨 - 50%折扣",
        shopNow: "立即購買",
        searchPlaceholder: "你在找什麼？"
    },
};