import api from "./Api";

export const getMenu = (language) => {
  return api.get("v1/categories/tree/" + language);
};

export const getProducts = (params) => {
  return api.post("v1/products/filter", params);
};
export const getProduct = (id,selectedLanguage) => {
  return api.get(`v1/products/${id}/${selectedLanguage}`);
};
export const getCollections = (params) => {
  return api.get("v1/collection/" + params);
};

export const getDeals = () => {
  return api.get("v1/deals");
};

export const userAddress = (params) => {
  return api.post("v1/address ", params);
};
export const getBanners = () => {
  return api.get("banners");
};

export const updateCart = (params) => {
  return api.post("v1/cart ", params);
};
export const getCart = (selectedLanguage, user) => {
  return api.get("v1/cart/" + user + "/" + selectedLanguage);
};
export const getFilters = (params) => {
  return api.get(`v1/filter/category?category=${params}`);
};
export const createPaymentIntent=(params)=>{
  return api.post("/payment/create-payment-intent ", params);
}