export const en = {
    deals: {
        seasonLabel: "This Season",
        title: "Jackpot Deals",
        viewDeals: "View Deals"
    },

    header: {
        announcement: "Summer Sale For All Swim Suits And Free Express Delivery - Off 50%",
        shopNow: "ShopNow",
        searchPlaceholder: "What are you looking for?"
    },
    labels: {
        today: "Today's",
        flashSales: "Flash Sales",
        thisSeason: "This Season",
        shopCollections: "Shop by collections",
        thisMonth: "This Month",
        bestSelling: "Best Selling Products",
        ourProducts: "Our Products",
        exploreProducts: "Explore Our Products",
        featured: "Featured",
        newArrivals: "New Arrivals",
        viewAll: "View All Products",
        viewCollection: "View Collection",
        addToCart: "Add To Cart",
        separator: "|",
        profile: "My Profile",
        address: "Address Book",
        payment: "My Payment Options",
        orders: "My Orders",
        returns: "My Returns",
        cancellations: "My Cancellations",
        wishlist: "My Wishlist",
        referral: "Referral",
        subscription: "Subscription",
        manageAccount: "Manage My Account",
        myOrders: "My Orders",
        others: "Others",
        editProfile: "Edit Your Profile",
        email: "Email",
        subscriptionType: "Subscription Type",
        referralCode: "Referral Code",
        referredBy: "Referred By",
        username: "Username",
        updatePassword: "Update Password",
        enterOtp: "Enter OTP",
        resendOtpIn: "Resend OTP in",
        resendOtp: "Resend OTP",
        setNewPassword: "Set New Password",
        savePassword: "Save Password",
        cancel: "Cancel",
        saveChanges: "Save Changes"
    }
};