
export const es = {
    deals: {
        seasonLabel: "Esta Temporada",
        title: "Ofertas Jackpot",
        viewDeals: "Ver Ofertas"
    },
    labels: {
        today: "De Hoy",
        flashSales: "Ofertas Relámpago",
        thisSeason: "Esta Temporada",
        shopCollections: "Comprar por colecciones",
        thisMonth: "Este Mes",
        bestSelling: "Productos Más Vendidos",
        ourProducts: "Nuestros Productos",
        exploreProducts: "Explorar Nuestros Productos",
        featured: "Destacados",
        newArrivals: "Nuevas Llegadas",
        viewAll: "Ver Todos los Productos",
        viewCollection: "Ver Colección",
        addToCart: "Añadir al Carrito",
        separator: "|",
        profile: "Mi Perfil",
        address: "Libreta de Direcciones",
        payment: "Mis Opciones de Pago",
        orders: "Mis Pedidos",
        returns: "Mis Devoluciones",
        cancellations: "Mis Cancelaciones",
        wishlist: "Mi Lista de Deseos",
        referral: "Referencia",
        subscription: "Suscripción",
        manageAccount: "Administrar Mi Cuenta",
        myOrders: "Mis Pedidos",
        others: "Otros",
        editProfile: "Editar Tu Perfil",
        email: "Correo Electrónico",
        subscriptionType: "Tipo de Suscripción",
        referralCode: "Código de Referencia",
        referredBy: "Referido Por",
        username: "Nombre de Usuario",
        updatePassword: "Actualizar Contraseña",
        enterOtp: "Ingresar OTP",
        resendOtpIn: "Reenviar OTP en",
        resendOtp: "Reenviar OTP",
        setNewPassword: "Establecer Nueva Contraseña",
        savePassword: "Guardar Contraseña",
        cancel: "Cancelar",
        saveChanges: "Guardar Cambios"
    },
    header: {
        announcement: "Venta de verano para todos los trajes de baño y entrega exprés gratuita - 50% de descuento",
        shopNow: "Compra Ahora",
        searchPlaceholder: "¿Qué estás buscando?"
    },
};