import React, { useEffect, useState, useRef, useCallback } from "react";
import "./ProductsSection.css";
import ProductCard from "../widget/ProductCard";
import "../Flash/FlashSales.css";
import { getProducts } from "../../util/ApiService";
import { useNavigate } from "react-router-dom";

const ProductsSection = ({ subcategories, listFilter }) => {
  const [products, setProducts] = useState([]);
  const [offset, setOffset] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [priceRange, setPriceRange] = useState([0, 500]);
  const [productFilter, setProductFilter] = useState({});
  const observer = useRef(null);
  const navigate = useNavigate();
  const [groupedData, setGroupedData] = useState();
// this is for filter and title purpose 
  useEffect(() => {
    if (listFilter) {
      const grouped = listFilter.reduce((acc, item) => {
        if (!acc[item.type]) {
          acc[item.type] = [];
        }
        acc[item.type].push(item);
        return acc;
      }, {});

      setGroupedData(grouped);

      const initialFilter = Object.keys(grouped).reduce((acc, key) => {
        acc[key] = [];
        return acc;
      }, {});
      setProductFilter(initialFilter);
    }
  }, [listFilter]);

  // API call to fetch products
  const fetchProducts = async () => {
    // if (isFetching || !hasMore) return;
    setIsFetching(true);

    const params = {
      offset: offset,
      pagesize: 10, // Increase by 20 on each scroll
      languageCode: "en",
    };
    Object.keys(productFilter || {}).forEach((key) => {
      const value = productFilter[key];
      if (Array.isArray(value) && value.length > 0) {
        params[key] = value;
      } else if (typeof value === "number" && value > 0) {
        params[key] = value;
      } else if (typeof value === "string" && value.trim().length > 0) {
        params[key] = value;
      }
    });
    await getProducts(params)
      .then((response) => {
        const newProducts = response.data.content;
        if (newProducts.length === 0) {
          setHasMore(false);
        } else {
          const filteredProducts = newProducts.filter(
            (newProduct) =>
              !products.some(
                (existingProduct) => existingProduct.id === newProduct.id
              )
          );
          setProducts((prev) => [...prev, ...filteredProducts]);
          setOffset((prev) => prev + 1); // Increase offset for the next call
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setIsFetching(false));
  };

  // Trigger fetchProducts on mount
  useEffect(() => {
    fetchProducts();
  }, [productFilter]);

  // Intersection Observer for Infinite Scroll
  useEffect(() => {
    if (!hasMore) return;

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        fetchProducts();
      }
    });

    const lastProduct = document.querySelector(".product-card:last-child");
    if (lastProduct) observer.current.observe(lastProduct);

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [products]);
  const handlePriceChange = (event) => {
    setPriceRange(event.target.value.split(",").map(Number));
  };
  const handleCardClick = (id) => {
    navigate(`/product/${id}`);
  };
  const handleFilterChange = (category, value) => {
    setProductFilter((prevFilters) => {
      const updatedCategory = [...prevFilters[category]];
      const index = updatedCategory.indexOf(value);

      if (index > -1) {
        updatedCategory.splice(index, 1);
      } else {
        updatedCategory.push(value);
      }

      return { ...prevFilters, [category]: updatedCategory };
    });
  };

  return (
    <div className="products-section">
      {/* Left-side Filters */}
      <div className="filters-container">
        <h3>Filters</h3>

        {/* Gender Filter */}
        <div className="filter-group">
          {groupedData &&
            Object?.keys(groupedData)?.map((type) => (
              <div>
                <h4>{type}</h4>
                <ul>
                  {groupedData[type]?.map((item) => (
                    <>
                      <li key={item?.id}>
                        {" "}
                        <input
                          type="checkbox"
                          id={item?.name}
                          name={item?.name}
                          onChange={() =>
                            handleFilterChange(item.type, item?.name)
                          }
                          checked={productFilter[item.type]?.includes(
                            item?.name
                          )}
                        />
                        <label htmlFor={item?.id}>{item?.name}</label>
                      </li>
                    </>
                  ))}
                </ul>
              </div>
            ))}
        </div>
        <div className="filter-group">
          <h4>Categories</h4>
          <ul>
            {subcategories?.map((sun) => (
              <li>
                <input type="checkbox" id="shoes" />{" "}
                <label htmlFor="shoes">{sun.name}</label>
              </li>
            ))}
          </ul>
        </div>
        {/* Price Range Filter */}
        <div className="filter-group">
          <h4>Price Range</h4>
          <input
            type="range"
            min="0"
            max="1000"
            step="10"
            value={priceRange}
            onChange={handlePriceChange}
            className="price-range"
          />
          <p>
            Price: ${priceRange[0]} - ${priceRange[1]}
          </p>
        </div>
      </div>

      {/* Right-side Product List */}
      <div className="products-list-container">
        {products.map((product) => (
          <div key={product.id} onClick={() => handleCardClick(product.id)}>
            <ProductCard product={product} />
          </div>
        ))}
      </div>

      {/* Loading Indicator */}
      {isFetching && <p className="loading">Loading more products...</p>}
    </div>
  );
};

export default ProductsSection;
