import React from "react";
import "./orders.css";

const OrdersList = ({ orders, onSelectOrder }) => {
  return (
    <div className="orders-container">
      <h2>Orders</h2>
      <table className="orders-table">
        <thead>
          <tr>
            <th>Order Code</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Total Amount</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order.id} onClick={() => onSelectOrder(order)}>
              <td>{order.orderCode}</td>
              <td>{order.name}</td>
              <td>{order.email}</td>
              <td>{order.phone}</td>
              <td>${order.total.toFixed(2)}</td>
              <td className={`status ${order.status.toLowerCase()}`}>
                {order.status}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrdersList;
