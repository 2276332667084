import React, { useEffect, useState,useContext } from 'react';
import './Collection.css';
import { getCollections } from '../../util/ApiService';
import { LanguageContext } from '../../context/LanguageContext';

const Collection = () => {
      const { t } = useContext(LanguageContext);
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('selectedLanguage') || 'en');
    const [visibleCollections, setVisibleCollections] = useState([]);
    
    useEffect(() => {
        const handleLanguageChange = () => {
            const newLanguage = localStorage.getItem('selectedLanguage');
            setSelectedLanguage(newLanguage);
        };
    
        window.addEventListener('languageChanged', handleLanguageChange);
        return () => window.removeEventListener('languageChanged', handleLanguageChange);
    }, []);
    

    useEffect(() => {
        const fetchCollections = async () => {
            try {
                const response = await getCollections(selectedLanguage);
                setVisibleCollections(response.data);
            } catch (error) {
                console.error('Failed to fetch collections:', error);
            }
        };

        fetchCollections();
    }, [selectedLanguage]);

    return (
        <div className="collection-container">
            <div className="header">
                <div className="today-label-wrapper">
                    <span className="separator">|</span>
                    <p className="today-label">{t('labels.thisSeason')}</p>
                </div>
                <div className="flash-sales-info">
                    <h3>{t('labels.shopCollections')}</h3>
                </div>
            </div>
            <div className="card-grid">
                {visibleCollections.map((item, index) => (
                    <div className="card" key={index}>
                        <div className="card-image">
                            <img src={item.imageUrl} alt={item.collectionName} />
                        </div>
                        <div className="card-content">
                            <h3>{item.collectionName}</h3>
                            <p>{item.description}</p>
                            <button className="view-button">{t('labels.viewCollection')}</button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Collection;