import React, { createContext, useState } from 'react';
import { translations } from '../i18n/i18n';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [currentLang, setCurrentLang] = useState('en');

  const t = (key) => {
    const keys = key.split('.');
    let translation = translations[currentLang];
    keys.forEach(k => {
      translation = translation[k];
    });
    return translation;
  };

  return (
    <LanguageContext.Provider value={{ currentLang, setCurrentLang, t }}>
      {children}
    </LanguageContext.Provider>
  );
};