import React, { useState, useEffect } from 'react';
import OrderDetails from './OrderDetails';
import OrdersList from './OrdersList';

const OrdersPage = () => {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [orders, setOrder] = useState([{
    "id": 1,
    "orderCode": "ORD123456",
    "name": "John Doe",
    "flatNo": "12B",
    "addrssLine1": "123 Main Street",
    "addrsesLine2": "Apt 4C",
    "zipocode": "10001",
    "phone": "+1 234 567 890",
    "email": "johndoe@example.com",
    "country": "USA",
    "tax": 5.99,
    "charges": "2.50",
    "tnxNumber": "TXN987654321",
    "status": "Shipped",
    "note": "Please handle with care.",
    "total": 150.75,
    "userId": "user_001",
    "createdTime": "2025-02-05T10:30:00",
    "updatedTime": "2025-02-05T12:00:00",
    "orderItems": [
      {
        "id": 101,
        "name": "Nike Running Shoes",
        "imageUrl": "https://via.placeholder.com/80",
        "sellingPrice": 75.50,
        "originalPrice": 90.00,
        "discountPercentage": 15,
        "description": "High-performance running shoes for daily training.",
        "brand": "Nike",
        "sku": "NK12345",
        "color": "Black",
        "size": "10",
        "subTotal": 75.50,
        "qty": 1,
        "delivery": "2025-02-10T15:00:00",
        "tnxNumber": "TXN987654321",
        "refundStatus": "Not Requested",
        "status": "Shipped",
        "vendorId": 501,
        "vendor": "Nike Official Store"
      },
      {
        "id": 102,
        "name": "Apple AirPods Pro",
        "imageUrl": "https://via.placeholder.com/80",
        "sellingPrice": 75.25,
        "originalPrice": 100.00,
        "discountPercentage": 25,
        "description": "Wireless noise-canceling earbuds with superior sound.",
        "brand": "Apple",
        "sku": "APL45678",
        "color": "White",
        "size": "Standard",
        "subTotal": 75.25,
        "qty": 1,
        "delivery": "2025-02-12T10:00:00",
        "tnxNumber": "TXN987654321",
        "refundStatus": "Not Requested",
        "status": "Processing",
        "vendorId": 502,
        "vendor": "Apple Store"
      }
    ]
  }]);

 
  return (
    <div className="orders-page">
      {selectedOrder ? (
        <OrderDetails order={selectedOrder} onBack={() => setSelectedOrder(null)} />
      ) : (
        <OrdersList orders={orders} onSelectOrder={setSelectedOrder} />
      )}
    </div>
  );
};

export default OrdersPage;
