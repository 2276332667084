import React from "react";
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import './Footer.css'
const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        {/* Exclusive - Subscribe Section */}
        <div className="footer-section">
          <h3>Exclusive</h3>
          <p>Subscribe</p>
          <p>Get 10% off your first order</p>
          <div className="subscribe-box">
            <input type="email" placeholder="Enter your email" />
            <button>→</button>
          </div>
        </div>

        {/* Support Section */}
        <div className="footer-section">
          <h3>Support</h3>
          <p>111 Bijoy Sarani, Dhaka, DH 1515, Bangladesh.</p>
          <p>exclusive@gmail.com</p>
          <p>+88015-88888-9999</p>
        </div>

        {/* Account Section */}
        <div className="footer-section">
          <h3>Account</h3>
          <p>My Account</p>
          <p>Login / Register</p>
          <p>Cart</p>
          <p>Wishlist</p>
          <p>Shop</p>
          <p>Vendor Sign up / Sign in</p>
        </div>

        {/* Quick Links Section */}
        <div className="footer-section">
          <h3>Quick Link</h3>
          <p>Privacy Policy</p>
          <p>Terms Of Use</p>
          <p>FAQ</p>
          <p>Contact</p>
        </div>

        {/* Download App Section */}
        <div className="footer-section">
          <h3>Download App</h3>
          <p>Save $3 with App New User Only</p>
          <div className="download-box">
            <img src="https://hexdocs.pm/qr_code/docs/qrcode.svg" alt="QR Code" className="qr-code" />
            <div className="app-links">
              <img src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg" alt="Google Play" />
              <img src="https://w7.pngwing.com/pngs/947/300/png-transparent-iphone-app-store-apple-store-text-logo-mobile-phones.png" alt="App Store" />
            </div>
          </div>
          <div className="social-icons">
            <FaFacebookF />
            <FaTwitter />
            <FaInstagram />
            <FaLinkedinIn />
          </div>
        </div>
      </div>

      {/* Copyright Section */}
      <div className="footer-bottom">
        <p>© Copyright MEMEMALLTV 2024. All rights reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
