import React from 'react';
import './Wishlist.css';
import { Star } from 'lucide-react';

const Wishlist = () => {
  // Sample wishlist data - in a real app this would come from props or state
  const wishlistItems = [
    {
      id: 1,
      name: 'Gucci duffle bag',
      price: 960,
      originalPrice: 1160,
      image: '/path-to-image/gucci-bag.jpg',
      discount: '-20%'
    },
    {
      id: 2,
      name: 'RGB liquid CPU Cooler',
      price: 1860,
      image: '/path-to-image/cpu-cooler.jpg'
    },
    {
      id: 3,
      name: 'GP11 Shooter USB Gamepad',
      price: 550,
      image: '/path-to-image/gamepad.jpg'
    },
    {
      id: 4,
      name: 'Quilted Satin Jacket',
      price: 750,
      image: '/path-to-image/jacket.jpg'
    }
  ];

  const recommendedItems = [
    {
      id: 5,
      name: 'ASUS FHD Gaming Laptop',
      price: 960,
      originalPrice: 1160,
      image: '/path-to-image/laptop.jpg',
      rating: 4,
      reviews: 88,
      discount: '-20%'
    },
    {
      id: 6,
      name: 'IPS LCD Gaming Monitor',
      price: 1160,
      image: '/path-to-image/monitor.jpg',
      rating: 5,
      reviews: 65
    },
    {
      id: 7,
      name: 'HAVIT HV-G92 Gamepad',
      price: 550,
      image: '/path-to-image/gamepad2.jpg',
      rating: 4,
      reviews: 65,
      isNew: true
    },
    {
      id: 8,
      name: 'AK-900 Wired Keyboard',
      price: 200,
      image: '/path-to-image/keyboard.jpg',
      rating: 4,
      reviews: 65
    },
    {
        id: 9,
        name: 'AK-900 Wired Keyboard',
        price: 200,
        image: '/path-to-image/keyboard.jpg',
        rating: 4,
        reviews: 65
      }
  ];

  const renderRatingStars = (rating) => {
    return [...Array(5)].map((_, index) => (
      <Star
        key={index}
        size={16}
        className={index < rating ? 'star-filled' : 'star-empty'}
      />
    ));
  };

  return (
    <div className="wishlist-container">
      <div className="wishlist-header">
        <h2>Wishlist (4)</h2>
        <button className="move-to-bag">Move All To Bag</button>
      </div>

      <div className="products-grid">
        {wishlistItems.map((item) => (
          <div className="product-card" key={item.id}>
            <div className="product-image">
              {item.discount && <span className="discount">{item.discount}</span>}
              <button className="remove-button">
                <span className="remove-icon">×</span>
              </button>
              <img src='loginscreen.png' alt={item.name} />
              <button className="add-to-cart">Add To Cart</button>
            </div>
            <div className="product-info">
              <h3>{item.name}</h3>
              <div className="price-info">
                <span className="current-price">${item.price}</span>
                {item.originalPrice && (
                  <span className="original-price">${item.originalPrice}</span>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="recommended-section">
        <div className="section-header">
          <h2>Just For You</h2>
          <button className="see-all">See All</button>
        </div>

        <div className="products-grid">
          {recommendedItems.map((item) => (
            <div className="product-card" key={item.id}>
              <div className="product-image">
                {item.discount && <span className="discount">{item.discount}</span>}
                {item.isNew && <span className="new-tag">NEW</span>}
                <button className="wishlist-button">♡</button>
                <img src={item.image} alt={item.name} />
                <button className="add-to-cart">Add To Cart</button>
              </div>
              <div className="product-info">
                <h3>{item.name}</h3>
                <div className="price-info">
                  <span className="current-price">${item.price}</span>
                  {item.originalPrice && (
                    <span className="original-price">${item.originalPrice}</span>
                  )}
                </div>
                <div className="rating">
                  {renderRatingStars(item.rating)}
                  <span className="reviews">({item.reviews})</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Wishlist;