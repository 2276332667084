import React, { useEffect, useState } from "react";
import "./AddressBook.css";
import { Pencil, Trash } from "lucide-react";
import { userAddress } from "../../util/ApiService";

const AddressBook = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("users")));
  const [addresses, setAddresses] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editingAddress, setEditingAddress] = useState(null);
  const [showForm, setShowForm] = useState(false);

  const initialFormState = {
    name: "",
    flatNo: "",
    addrssLine1: "",
    addrsesLine2: "",
    zipocode: "",
    phone: "",
    email: "",
    country: "",
    default: false,
  };

  const [formData, setFormData] = useState(initialFormState);

  // Fetch addresses from API
  useEffect(() => {
    const params = {
      action: "X",
      userId: 1,
    };

    userAddress(params)
      .then((response) => {
        setAddresses(response.data);
      })
      .catch((error) => console.error("Error fetching addresses:", error));
  }, []);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Handle Edit
  const handleEdit = (address) => {
    setIsEditing(true);
    setEditingAddress(address);
    setFormData(address);
    setShowForm(true);
  };

  // Handle Delete
  const handleDelete = (id) => {
    const params = {
      action: "D",
      id: id,
      userId: 1,
    };

    userAddress(params)
      .then(() => {
        setAddresses((prev) => prev.filter((addr) => addr.id !== id));
      })
      .catch((error) => console.error("Error deleting address:", error));
  };

  // Handle Submit (Add or Update)
  const handleSubmit = (e) => {
    e.preventDefault();
    let actionType = isEditing ? "U" : "I";

    const updatedData = {
      ...formData,
      id: isEditing ? editingAddress.id : Math.max(...addresses.map((a) => a.id), 0) + 1,
      userId: 1,
      action: actionType,
    };

    userAddress(updatedData)
      .then((response) => {
        if (isEditing) {
          setAddresses((prev) =>
            prev.map((addr) =>
              addr.id === editingAddress.id ? { ...updatedData } : addr
            )
          );
        } else {
          setAddresses((prev) => [...prev, updatedData]);
        }
      })
      .catch((error) => console.error("Error saving address:", error));

    setShowForm(false);
    setIsEditing(false);
    setFormData(initialFormState);
  };

  return (
    <div className="address-book">
      <div className="header">
        <button onClick={() => setShowForm(true)} className="add-button">
          + Add New Address
        </button>
      </div>

      {showForm && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>{isEditing ? "Edit Address" : "Add New Address"}</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-grid">
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Phone</label>
                  <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Flat/House No.</label>
                  <input
                    type="text"
                    name="flatNo"
                    value={formData.flatNo}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Address Line 1</label>
                  <input
                    type="text"
                    name="addrssLine1"
                    value={formData.addrssLine1}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Address Line 2</label>
                  <input
                    type="text"
                    name="addrsesLine2"
                    value={formData.addrsesLine2}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label>Zip Code</label>
                  <input
                    type="text"
                    name="zipocode"
                    value={formData.zipocode}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Country</label>
                  <input
                    type="text"
                    name="country"
                    value={formData.country}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Email (Optional)</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email || ""}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="checkbox-group">
                <input
                  type="checkbox"
                  name="default"
                  checked={formData.default}
                  onChange={handleInputChange}
                />
                <label>Make this my default address</label>
              </div>
              <div className="form-actions">
                <button
                  type="button"
                  onClick={() => {
                    setShowForm(false);
                    setIsEditing(false);
                    setFormData(initialFormState);
                  }}
                  className="cancel-button"
                >
                  Cancel
                </button>
                <button type="submit" className="submit-button">
                  {isEditing ? "Save Changes" : "Add Address"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <div className="address-list">
        {addresses.map((address) => (
          <div key={address.id} className="address-card">
            <div className="address-content">
              <div className="address-details">
                <h3>{address.name}</h3>
                <p className="phone">{address.phone}</p>
                <p className="address">
                  {address.flatNo}, {address.addrssLine1}
                  {address.addrsesLine2 && `, ${address.addrsesLine2}`}
                </p>
                <p>{address.zipocode}</p>
                <p>{address.country}</p>
              </div>
              <div className="address-actions">
                <button onClick={() => handleEdit(address)} className="edit-button">
                  <Pencil />
                </button>
                <button onClick={() => handleDelete(address.id)} className="delete-button">
                  <Trash />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddressBook;
