import React from "react";
import { useNavigate } from "react-router-dom";

const SuccessPage = () => {
  const navigate = useNavigate();

  return (
    <div className="success-container">
      <h2>Payment Successful! 🎉</h2>
      <p>Order Number For Tracking #123456</p>
      <p>Thank you for your purchase.</p>
      <button onClick={() => navigate("/")}>Continue Shopping</button>
    </div>
  );
};

export default SuccessPage;
