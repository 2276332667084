import React, { useEffect, useState } from 'react';
import './Subscription.css';
import { getPlans } from '../../util/AuthApiService'

const Subscription = () => {
  const [selectedPlan, setSelectedPlan] = useState('Diamond');
  const[plans,setPlans]=useState([]) ;
  useEffect(()=>{
    getPlans().then((response)=>{
        setPlans(response.data)
    }).catch((err)=>{console.log(err)})
  },[])
  const getPlanColor = (planName) => {
    switch(planName) {
      case 'Silver': return '#718096';
      case 'Gold': return '#ECC94B';
      case 'Diamond': return '#4FD1C5';
      default: return '#718096';
    }
  };

  const handleSubscribe = (planName) => {
    setSelectedPlan(planName);
    // Add your subscription logic here
  };

  const formatDescription = (description) => {
    return description.split('\\n').map((line, index) => (
      <li key={index}>
        <svg className="feature-icon" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
        </svg>
        {line.trim()}
      </li>
    ));
  };

  return (
    <div className="subscription-container">
      <div className="subscription-header">
        <h1>Choose Your Subscription Plan</h1>
        <p>Unlock premium benefits with our subscription plans</p>
      </div>

      <div className="plans-container">
        {plans.map((plan) => (
          <div 
            key={plan.id}
            className={`plan-card ${selectedPlan === plan.name ? 'selected' : ''} ${plan.name === 'Diamond' ? 'recommended' : ''}`}
            style={{
              '--plan-color': getPlanColor(plan.name),
              '--plan-shadow': `${getPlanColor(plan.name)}33`
            }}
          >
            {plan.name === 'Diamond' && (
              <div className="recommended-badge">
                Recommended
              </div>
            )}
            
            <div className="plan-header">
              <h2 className="plan-name">{plan.name}</h2>
              <div className="plan-price">
                <span className="currency">$</span>
                <span className="amount">{plan.price.toFixed(2)}</span>
                {plan.price > 0 && <span className="period">/month</span>}
                {plan.price === 0 && <span className="free-tag">FREE</span>}
              </div>
            </div>

            <ul className="plan-features">
              {formatDescription(plan.description)}
            </ul>

            <button 
              className={`subscribe-button ${selectedPlan === plan.name ? 'subscribed' : ''}`}
              onClick={() => handleSubscribe(plan.name)}
            >
              {selectedPlan === plan.name ? 'Current Plan' : 'Subscribe Now'}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Subscription;