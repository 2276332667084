import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import "./CheckoutPage.css";
import { userAddress, getCart } from "../../util/ApiService";
import { useNavigate } from "react-router-dom";

const CheckoutPage = () => {
  const navigate = useNavigate();
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [showNewAddressForm, setShowNewAddressForm] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("users")));
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || "en"
  );
  const [placeOrder, setCartData] = useState(null);
  const uuid = sessionStorage.getItem("uuid");

  useEffect(() => {
    userAddress({ action: "X", userId: user?.id | uuid })
      .then((response) => {
        setAddresses(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
    getCart(selectedLanguage, user?.email || uuid)
      .then((response) => {
        setCartData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Save the selected address in localStorage when it changes
  const handleAddressSelection = (address) => {
    setSelectedAddress(address);
    setShowNewAddressForm(false);
  };

  // Save payment method to localStorage
  const handlePaymentChange = (method) => {
    setPaymentMethod(method);
  };

  const calculateSubtotal = () => {
    return (
      placeOrder &&
      placeOrder.productCart.reduce((sum, item) => sum + item.subTotal, 0)
    );
  };

  const onSubmit = (data) => {
    navigate("payment");
    console.log("Order submitted:", data);
  };

  return (
    <div className="checkout-container">
      <div className="checkout-breadcrumb">
        <span>Accounts</span> / <span>My Account</span> / <span>Product</span> /
        <span>View Cart</span> / <span className="active">CheckOut</span>
      </div>

      <div className="checkout-content">
        <div className="billing-form">
          <h2>Billing Details</h2>

          {addresses&& !showNewAddressForm && (
            <div className="saved-addresses">
              <h3>Select a Saved Address</h3>
              {addresses.map((address) => (
                <div
                  key={address?.id}
                  className={`address-card ${
                    selectedAddress?.id === address?.id ? "selected" : ""
                  }`}
                  onClick={() => handleAddressSelection(address)}
                >
                  <div className="address-details">
                    <p className="name">{address.name}</p>
                    <p>{address.flatNo}</p>
                    <p>{address.addrssLine1}</p>
                    <p>{address.addrsesLine2}</p>
                    <p>{address.zipocode}</p>
                    <p>{address.phone}</p>
                  </div>
                </div>
              ))}
              <button
                className="new-address-btn"
                onClick={() => setShowNewAddressForm(true)}
              >
                Add New Address
              </button>
            </div>
           )} 

          {showNewAddressForm && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="First Name*"
                  {...register("firstName", { required: true })}
                  className={errors.firstName ? "error" : ""}
                />
              </div>

              <div className="form-group">
                <input
                  type="text"
                  placeholder="Company Name"
                  {...register("companyName")}
                />
              </div>

              <button
                type="button"
                className="back-to-addresses"
                onClick={() => setShowNewAddressForm(false)}
              >
                Back to Saved Addresses
              </button>
            </form>
          )} 
        </div>

        <div className="order-summary">
          <div className="cart-items">
            {placeOrder &&
              placeOrder.productCart.map((item) => (
                <div key={item.cartId} className="cart-item">
                  <img src={item.imageUrl} alt={item.name} />
                  <div className="item-details">
                    <h3>{item.name}</h3>
                    <p>${item.sellingPrice}</p>
                  </div>
                </div>
              ))}
          </div>

          <div className="order-totals">
            <div className="subtotal">
              <span>Subtotal:</span>
              <span>${calculateSubtotal()}</span>
            </div>
            <div className="shipping">
              <span>Shipping:</span>
              <span>Free</span>
            </div>
            <div className="total">
              <span>Total:</span>
              <span>${calculateSubtotal()}</span>
            </div>
          </div>

          <div className="payment-methods">
            <div className="payment-option">
              <input
                type="radio"
                id="bank"
                name="payment"
                value="bank"
                checked={paymentMethod === "bank"}
                onChange={(e) => handlePaymentChange(e.target.value)}
              />
              <label htmlFor="bank">Bank</label>
            </div>
            <div className="payment-option">
              <input
                type="radio"
                id="cash"
                name="payment"
                value="cash"
                checked={paymentMethod === "cash"}
                onChange={(e) => handlePaymentChange(e.target.value)}
              />
              <label htmlFor="cash">Cash on delivery</label>
            </div>
          </div>

          <button
            className="place-order"
            onClick={handleSubmit(onSubmit)}
            disabled={
              !selectedAddress && addresses.length > 0 && !showNewAddressForm
            }
          >
            Place Order
          </button>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPage;
