import React,{useState} from "react";
import FlashSales from "./Flash/FlashSale";
import Collections from "../components/Collection/Collection";
import BestSellingProducts from "../components/BestSellingProducts";
import NewArrival from "../components/NewArrivals";
import ExploreOurProducts from "../components/ExploreOurProducts";
import Deals from "./Deals/Deals";

const HomePage = (setIsClicked) => {
  return (
    <div>
      <FlashSales  setIsClicked={setIsClicked}/>
      <Collections />
      <BestSellingProducts />
      <Deals/>
      <ExploreOurProducts />
      <NewArrival />
    </div>
  );
};

export default HomePage;
