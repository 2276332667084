export const cn = {
    deals: {
        seasonLabel: "本季",
        title: "特价优惠",
        viewDeals: "查看优惠"
    },
    labels: {
        today: "今日",
        flashSales: "限时特卖",
        thisSeason: "本季",
        shopCollections: "按系列购物",
        thisMonth: "本月",
        bestSelling: "畅销产品",
        ourProducts: "我们的产品",
        exploreProducts: "探索我们的产品",
        featured: "精选",
        newArrivals: "新品上市",
        viewAll: "查看所有产品",
        viewCollection: "查看系列",
        addToCart: "加入购物车",
        separator: "|",
        profile: "我的资料",
        address: "地址簿",
        payment: "我的付款选项",
        orders: "我的订单",
        returns: "我的退货",
        cancellations: "我的取消",
        wishlist: "我的愿望清单",
        referral: "推荐",
        subscription: "订阅",
        manageAccount: "管理我的账户",
    myOrders: "我的订单",
    others: "其他",
    editProfile: "编辑您的个人资料",
    email: "电子邮件",
    subscriptionType: "订阅类型",
    referralCode: "推荐码",
    referredBy: "被推荐人",
    username: "用户名",
    updatePassword: "更新密码",
    enterOtp: "输入 OTP",
    resendOtpIn: "在...内重新发送 OTP",
    resendOtp: "重新发送 OTP",
    setNewPassword: "设置新密码",
    savePassword: "保存密码",
    cancel: "取消",
    saveChanges: "保存更改"
    },

    header: {
        announcement: "所有泳装夏季促销，免费特快送货 - 50%折扣",
        shopNow: "立即购买",
        searchPlaceholder: "你在找什么"
    },
};