import React, { useEffect, useState } from "react";
import "../../Product/ProductsSection.css";
import ProductCard from "../../widget/ProductCard";
import "../../Flash/FlashSales.css";
import { getProducts } from "../../../util/ApiService";
import { useNavigate } from "react-router-dom";

const ProductsSection = () => {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  const fetchProducts = async () => {
    const params = {
      offset: 0,
      pagesize: 10, // Increase by 20 on each scroll
      languageCode: "en",
    };

    await getProducts(params)
      .then((response) => {
        const newProducts = response.data.content;

        setProducts(newProducts);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleCardClick = (id) => {
    navigate(`/product/${id}`);
    window.location.reload();
  };
  return (
    <div className="products-section">
      <div className="products-list-container">
        {products.map((product) => (
          <div key={product.id} onClick={() => handleCardClick(product.id)}>
            <ProductCard product={product} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductsSection;
