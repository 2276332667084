import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getBanners } from "../../util/ApiService";
import "./HeroCarousel.css";

const HeroCarousel = ({ subcategories }) => {
  const [visibleSubcategories, setVisibleSubcategories] = useState([]);
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    setVisibleSubcategories(subcategories);
  }, [subcategories]);

  useEffect(() => {
    getBanners().then((response) => {
      setBanners(response.data);
    }).catch((err)=>{console.log(err)});
  }, []);

  // Slider settings
  const settings = {
    dots: true,
    infinite: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // Slide changes every 3 seconds
    pauseOnHover: true,
    arrows: false, // Hide arrows for a cleaner look
  };

  return (
    <div className="hero-container">
      <div className="hero-center">
        <div className="menu-list">
          <ul>
            {visibleSubcategories.length > 0 ? (
              visibleSubcategories.map((sub, index) => (
                <li key={index}>{sub.name}</li>
              ))
            ) : (
              <li>No subcategories available</li>
            )}
          </ul>
        </div>
        <div className="border-divider"></div>
        <div className="carousel-container">
          <Slider {...settings}>
            {banners.map((banner, index) => (
              <div key={index} className="slide">
                <img src={banner.imageUrl} alt={banner.title} className="banner-image" />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default HeroCarousel;
