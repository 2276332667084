export const jp = {
    deals: {
        seasonLabel: "今シーズン",
        title: "ジャックポット特価",
        viewDeals: "特価を見る"
    },
    labels: {
        today: "本日の",
        flashSales: "タイムセール",
        thisSeason: "今シーズン",
        shopCollections: "コレクションで購入",
        thisMonth: "今月",
        bestSelling: "人気商品",
        ourProducts: "当社の商品",
        exploreProducts: "商品を探す",
        featured: "おすすめ",
        newArrivals: "新着商品",
        viewAll: "すべての商品を見る",
        viewCollection: "コレクションを見る",
        addToCart: "カートに追加",
        separator: "|",
        profile: "マイプロフィール",
        address: "アドレス帳",
        payment: "支払いオプション",
        orders: "私の注文",
        returns: "返品",
        cancellations: "キャンセル",
        wishlist: "ウィッシュリスト",
        referral: "紹介",
        subscription: "サブスクリプション",
        manageAccount: "アカウント管理",
        myOrders: "私の注文",
        others: "その他",
        editProfile: "プロフィールを編集",
        email: "メール",
        subscriptionType: "サブスクリプションの種類",
        referralCode: "紹介コード",
        referredBy: "紹介者",
        username: "ユーザー名",
        updatePassword: "パスワードを更新",
        enterOtp: "OTP を入力",
        resendOtpIn: "OTP を再送信まで",
        resendOtp: "OTP を再送信",
        setNewPassword: "新しいパスワードを設定",
        savePassword: "パスワードを保存",
        cancel: "キャンセル",
        saveChanges: "変更を保存"
    },
    header: {
        announcement: "すべての水着のサマーセール＆無料エクスプレス配送 - 50%オフ",
        shopNow: "今すぐ購入",
        searchPlaceholder: "何をお探しですか？"
    },
};

