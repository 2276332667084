import React from "react";
import { useNavigate } from "react-router-dom";

const FailurePage = () => {
  const navigate = useNavigate();

  return (
    <div className="failure-container">
      <h2>Payment Failed 😢</h2>
      <p>Something went wrong with your transaction.</p>
      <button onClick={() => navigate("/checkout")}>Try Again</button>
    </div>
  );
};

export default FailurePage;
