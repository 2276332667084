import React, { useState,useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/Footer/Footer";
import HomePage from "./components/HomePage";
import ProductDetails from "./components/ProductDetails/ProductDetails";
import HeroCarousel from "./components/HeroCarousel/HeroCarousel";
import ProductsSection from "./components/Product/ProductsSection";
import Cart from "./components/cart/Cart";
import CheckoutPage from "./components/Checkout/CheckoutPage";
import Auth from "./components/Login/Auth";
import Wishlist from "./components/Wishlist/Wishlist";
import ProfileManagement from "./components/ProfileManagement";
import SuccessPage from "./components/Checkout/SuccessPage";
import FailurePage from "./components/Checkout/FailurePage";
import PaymentPage from "./components/Checkout/CheckoutForm";
import { LanguageProvider } from "./context/LanguageContext";
import { v4 as uuid } from "uuid";

function App() {
  const [subcategories, setSubcategories] = useState([]);
  const [isClicked, setIsClicked] = useState(false);
  const [listFilter, setListFilter] = useState([]);


  useEffect(() => {
    const hasIdExists = sessionStorage.getItem("uuid");
    const unique_id = uuid();
    if (!hasIdExists) {
      sessionStorage.setItem("uuid", unique_id);
    }
  }, []);
  return (
    <LanguageProvider>
      <Router>
        <div className="App">
          <Header
            setSubcategories={setSubcategories}
            setIsClicked={setIsClicked}
            setFilter={setListFilter}
          />
          <Routes>
            <Route
              path="/"
              element={
                <>
                  {!isClicked && <HeroCarousel subcategories={subcategories} />}
                  <HomePage setIsClicked={setIsClicked} />
                </>
              }
            />
            <Route path="/product/:id" element={<ProductDetails />} />
            <Route
              path="/product"
              element={
                <ProductsSection
                  subcategories={subcategories}
                  setIsClicked={setIsClicked}
                  listFilter={listFilter}
                />
              }
            />
            <Route path="/cart" element={<Cart />} />
            <Route path="/cart/checkout" element={<CheckoutPage />} />
            <Route path="/login" element={<Auth />} />
            <Route path="/wishlist" element={<Wishlist />} />
            <Route path="/profile" element={<ProfileManagement />} />
            <Route path="/success" element={<SuccessPage />} />
            <Route path="/failure" element={<FailurePage />} />
            <Route path="/cart/checkout/payment" element={<PaymentPage />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </LanguageProvider>
  );
}

export default App;
