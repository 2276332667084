import React, { useState,useContext } from 'react';
import './ProfileManagement.css';
import AddressBook from './Addressbook/AddressBook';
import Wishlist from './Wishlist/Wishlist';
import Referral from './Referral/Referral';
import Subscription from './Subscription/Subscription';
import OrdersPage from './order/OrdersPage';
import ProfileContent from './Profile/Profile';
import { LanguageContext } from '../context/LanguageContext';


const ProfileManagement = () => {
  const [activeSection, setActiveSection] = useState('profile');
  const { t } = useContext(LanguageContext);

  const menuItems = {
    account: [
      { id: 'profile', label: t('labels.profile') },
      { id: 'address', label: t('labels.address') },
      { id: 'payment', label: t('labels.payment') },
    ],
    orders: [
      { id: 'orders', label: t('labels.orders') },
      { id: 'returns', label: t('labels.returns') },
      { id: 'cancellations', label: t('labels.cancellations') },
  
    ],
    other: [
      { id: 'wishlist', label: t('labels.wishlist') },
      { id: 'referral', label: t('labels.referral') },
      { id: 'subscription', label: t('labels.subscription') }
    ]
  };



  const renderContent = () => {
    switch (activeSection) {
      case 'profile':
        return <ProfileContent />;
      case 'address':
        return <AddressBook/>;
      case 'wishlist':
        return <Wishlist/>;  
      case 'referral':
        return <Referral/>;  
      case 'subscription':
        return <Subscription/>;  
      case 'orders':
        return <OrdersPage/>;  
      case 'returns':
        return <OrdersPage/>;  
      case 'cancellations':
        return <OrdersPage/>;  
        
      default:
        return <div className="placeholder-content">Content for {activeSection}</div>;
    }
  };

  return (
    <div className="profile-management">
      <div className="breadcrumb">
        <div className="breadcrumb-links">
          <span>Home</span>
          <span className="separator">/</span>
          <span>My Account</span>
        </div>
        <div className="welcome-text">
          Welcome! <span className="user-name">Md Rimel</span>
        </div>
      </div>

      <div className="main-container">
        {/* Sidebar */}
        <div className="sidebar">
          <div className="menu-section">
            <h2 className="menu-title">Manage My Account</h2>
            <div className="menu-items">
              {menuItems.account.map((item) => (
                <button
                  key={item.id}
                  onClick={() => setActiveSection(item.id)}
                  className={`menu-item ${activeSection === item.id ? 'active' : ''}`}
                >
                  {item.label}
                </button>
              ))}
            </div>
          </div>

          <div className="menu-section">
            <h2 className="menu-title">My Orders</h2>
            <div className="menu-items">
              {menuItems.orders.map((item) => (
                <button
                  key={item.id}
                  onClick={() => setActiveSection(item.id)}
                  className={`menu-item ${activeSection === item.id ? 'active' : ''}`}
                >
                  {item.label}
                </button>
              ))}
            </div>
          </div>
          <div className="menu-section">
          <h2 className="menu-title">Others</h2>
          <div className="menu-items">
            {menuItems.other.map((item) => (
              <button
                key={item.id}
                onClick={() => setActiveSection(item.id)}
                className={`menu-item ${activeSection === item.id ? 'active' : ''}`}
              >
                {item.label}
              </button>
            ))}
          </div>
        </div>
        </div>
        {/* Main Content */}
        <div className="content-container">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default ProfileManagement;