import React, { useEffect, useState } from "react";
import { Trash2 } from "lucide-react";
import "./Cart.css";
import { useNavigate } from "react-router-dom";
import { updateCart, getCart } from "../../util/ApiService";
import { useSnackbar } from 'react-simple-snackbar'

const Cart = () => {
  const navigate = useNavigate();
  const [cartData, setCartData] = useState([]);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("users")));
  const [enqueueSnackbar, closeSnackbar] = useSnackbar()
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || "en"
  );
  const uuid = sessionStorage.getItem("uuid");
  useEffect(() => {
    fetchCart();
  }, []);
  const handleCartUpdate = (item, qty, isDeleted) => {
    const params = {
      sku: item.sku,
    };
    if (!isDeleted) {
      params.action = "U";
      params.qty = qty;
      params.id = item.cartId;
    } else {
      params.action = "D";
      params.userId = user?.email || uuid;
    }
    updateCart(params)
      .then((response) => {
        fetchCart();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchCart = () => {
    getCart(selectedLanguage, user?.email || uuid)
      .then((response) => {
        if (response.data) {
          setCartData(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCheckout = () => {
    navigate("checkout");
  };
  const [couponCode, setCouponCode] = useState("");

  const updateQuantity = (cartId, newQuantity, item) => {
    if (newQuantity < 1) return; // Prevent quantity from going below 1

    setCartData((prevData) => ({
      ...prevData,
      productCart: prevData.productCart.map((item) =>
        item.cartId === cartId
          ? {
              ...item,
              qty: newQuantity,
              subTotal: Number((newQuantity * item.sellingPrice).toFixed(2)),
            }
          : item
      ),
    }));
    handleCartUpdate(item, newQuantity, false);
  };

  const handleQuantityChange = (cartId, value) => {
    const newQuantity = parseInt(value);
    if (!isNaN(newQuantity) && newQuantity >= 1) {
      updateQuantity(cartId, newQuantity);
    }
  };

  const deleteItem = (item, cartId) => {
    handleCartUpdate(item, null, true);
    setCartData((prevData) => ({
      ...prevData,
      productCart: prevData.productCart.filter(
        (item) => item.cartId !== cartId
      ),
    }));
  };

  const calculateTotal = () => {
    return (
      cartData?.productCart?.reduce(
        (sum, item) => sum + (item.subTotal || 0),
        0
      ) || 0
    );
  };

  return (
    <div className="cart-container">
      <div className="cart-header">
        <a href="/" className="home-link">
          Home
        </a>
        <span className="separator">/</span>
        <span className="current-page">Cart</span>
      </div>

      <div className="cart-content">
        <div className="cart-items-section">
          <div className="cart-table-header">
            <div className="header-product">Product</div>
            <div className="header-price">Price</div>
            <div className="header-size">Size</div>
            <div className="header-color">Color</div>
            <div className="header-quantity">Quantity</div>
            <div className="header-subtotal">Subtotal</div>
            <div className="header-action">Action</div>
          </div>

          {cartData.productCart &&
            cartData.productCart.map((item) => (
              <div key={item.cartId} className="cart-item-cart">
                <div className="item-product">
                  <img src={item.imageUrl} alt={item.name} />
                  <div className="product-details">
                    <span className="product-name">{item.name}</span>
                    {/* <span className="product-sku">SKU: {item.sku}</span> */}
                    <span className="product-brand">Brand: {item.brand}</span>
                  </div>
                </div>
                <div className="item-price">
                  <div className="price-details">
                    <span className="selling-price">${item.sellingPrice}</span>
                    <span className="original-price">
                      ${item.originalPrice}
                    </span>
                    <span className="discount">
                      -{item.discountPercentage}%
                    </span>
                  </div>
                </div>
                <div className="item-size">{item.size}</div>
                <div className="item-color">
                  <div
                    className="color-swatch"
                    style={{ backgroundColor: item.color }}
                  ></div>
                </div>
                <div className="item-quantity">
                  <div className="quantity-control">
                    <button
                      className="qty-btn"
                      onClick={() =>
                        updateQuantity(item.cartId, item.qty - 1, item)
                      }
                    >
                      -
                    </button>
                    <input
                      type="number"
                      value={item.qty}
                      onChange={(e) =>
                        handleQuantityChange(item.cartId, e.target.value)
                      }
                      min="1"
                    />
                    <button
                      className="qty-btn"
                      onClick={() =>
                        updateQuantity(item.cartId, item.qty + 1, item)
                      }
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="item-subtotal">${item.subTotal.toFixed(2)}</div>
                <div className="item-action">
                  <button
                    className="delete-button"
                    onClick={() => deleteItem(item, item.cartId)}
                  >
                    <Trash2 size={20} />
                  </button>
                </div>
              </div>
            ))}

          <div className="cart-actions">
            <button className="return-shop">Return To Shop</button>
            <button className="update-cart">Update Cart</button>
          </div>

          <div className="coupon-section">
            <input
              type="text"
              placeholder="Coupon Code"
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
            />
            <button className="apply-coupon">Apply Coupon</button>
          </div>
        </div>

        <div className="cart-total">
          <h2>Cart Total</h2>
          <div className="total-row">
            <span>Subtotal:</span>
            <span>${calculateTotal().toFixed(2)}</span>
          </div>
          <div className="total-row">
            <span>Shipping:</span>
            <span>Free</span>
          </div>
          <div className="total-row total">
            <span>Total:</span>
            <span>${calculateTotal().toFixed(2)}</span>
          </div>
          <button className="checkout-button" onClick={() => cartData?.productCart?.length>0?handleCheckout():enqueueSnackbar("Currently cart is empty!")}>
            Process to checkout
          </button>
        </div>
      </div>
    </div>
  );
};

export default Cart;
