import auth from './AuthApi';

export const login = ( params ) => {  
    return auth.post('auth/login',params);
};

export const getUser = ( params ) => {  
    return auth.get('users/details?userId='+params);
};
export const getPlans = () => {  
    return auth.get('subscriptions/plan');
};
export const getCredits = (params) => {  
    return auth.get('credit/'+params);
};
