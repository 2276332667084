import { Heart, ShoppingCart } from 'lucide-react';
import React, { useState, useEffect,useContext } from 'react';
import './ProductCard.css';  // Import the CSS file
import { LanguageContext } from '../../context/LanguageContext';

const ProductCard = ({ product }) => {
    const { t } = useContext(LanguageContext);
  
    return (
      <div className="product-card-g">
            <div className="product-image">
              {product.discountPercentage && <span className="discount">-{product.discountPercentage} %</span>}
              <img src={product.imageUrl} alt={product.name} />
            </div>

            <div className="product-info-g">
              <h3>{product.name}</h3>
              <Heart className="wishlist-icon" />
              <div className="price-info">
                <span className="current-price">${product.originalPrice-(product.originalPrice*product.discountPercentage/100).toFixed(2)}</span>
                {product.originalPrice && (
                  <span className="original-price">${product.originalPrice}</span>
                )}
              </div>
              <button className="add-to-cart"><ShoppingCart size={18} /> {t('labels.addToCart')}</button>
            </div>
          </div>
    );
};

export default ProductCard;


