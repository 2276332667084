import React, { useEffect, useState } from 'react';
import './OrderDetails.css';

const OrderDetails = ({ order }) => {
  if (!order) return <p>Loading...</p>;

  return (
    <div className="order-container-d">
      <div className="order-header">
        <h2>Order #{order.orderCode}</h2>
        <p>Status: <span className={`status ${order.status.toLowerCase()}`}>{order.status}</span></p>
      </div>

      <div className="order-info">
        <h3>Shipping Details</h3>
        <p><strong>Name:</strong> {order.name}</p>
        <p><strong>Address:</strong> {order.flatNo}, {order.addrssLine1}, {order.addrsesLine2}</p>
        <p><strong>Zip Code:</strong> {order.zipocode}</p>
        <p><strong>Phone:</strong> {order.phone}</p>
        <p><strong>Email:</strong> {order.email}</p>
        <p><strong>Country:</strong> {order.country}</p>
      </div>

      <div className="order-summary">
        <h3>Order Summary</h3>
        <p><strong>Transaction ID:</strong> {order.tnxNumber}</p>
        <p><strong>Tax:</strong> ${order.tax}</p>
        <p><strong>Additional Charges:</strong> ${order.charges}</p>
        <p><strong>Total Amount:</strong> ${order.total}</p>
      </div>

      <h3>Order Items</h3>
      <div className="order-items">
        {order.orderItems.map((item) => (
          <div className="order-item-card" key={item.id}>
            <img src={item.imageUrl} alt={item.name} className="product-image-o" />
            <div className="item-details">
              <h4>{item.name}</h4>
              <p><strong>Brand:</strong> {item.brand}</p>
              <p><strong>Color:</strong> {item.color}</p>
              <p><strong>Size:</strong> {item.size}</p>
              <p><strong>Qty:</strong> {item.qty}</p>
              <p><strong>Price:</strong> ${item.sellingPrice}</p>
              <p><strong>Subtotal:</strong> ${item.subTotal}</p>
              <p><strong>Status:</strong> {item.status}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderDetails;
