import axios from 'axios';

const auth = axios.create({
  baseURL: 'http://mememall.tv:8082/api/', 
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  }
});

auth.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('API Error:', error.response || error.message);
    return Promise.reject(error);
  }
);

export default auth;
