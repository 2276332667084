export const fr = {
    deals: {
        seasonLabel: "Cette Saison",
        title: "Offres Jackpot",
        viewDeals: "Voir les Offres"
    },
    labels: {
        today: "Aujourd'hui",
        flashSales: "Ventes Flash",
        thisSeason: "Cette Saison",
        shopCollections: "Acheter par collections",
        thisMonth: "Ce Mois",
        bestSelling: "Meilleures Ventes",
        ourProducts: "Nos Produits",
        exploreProducts: "Explorer Nos Produits",
        featured: "En Vedette",
        newArrivals: "Nouveautés",
        viewAll: "Voir Tous les Produits",
        viewCollection: "Voir la Collection",
        addToCart: "Ajouter au Panier",
        separator: "|",
        profile: "Mon Profil",
        address: "Carnet d'Adresses",
        payment: "Mes Options de Paiement",
        orders: "Mes Commandes",
        returns: "Mes Retours",
        cancellations: "Mes Annulations",
        wishlist: "Ma Liste de Souhaits",
        referral: "Parrainage",
        subscription: "Abonnement",
        manageAccount: "Gérer Mon Compte",
    myOrders: "Mes Commandes",
    others: "Autres",
    editProfile: "Modifier Votre Profil",
    email: "E-mail",
    subscriptionType: "Type d'Abonnement",
    referralCode: "Code de Parrainage",
    referredBy: "Parrainé Par",
    username: "Nom d'Utilisateur",
    updatePassword: "Mettre à Jour le Mot de Passe",
    enterOtp: "Saisir OTP",
    resendOtpIn: "Renvoyer OTP dans",
    resendOtp: "Renvoyer OTP",
    setNewPassword: "Définir un Nouveau Mot de Passe",
    savePassword: "Enregistrer le Mot de Passe",
    cancel: "Annuler",
    saveChanges: "Enregistrer les Modifications"
    },
    header: {
        announcement: "Soldes d'été sur tous les maillots de bain et livraison express gratuite - 50% de réduction",
        shopNow: "Acheter Maintenant",
        searchPlaceholder: "Que cherchez-vous?"
    },
};